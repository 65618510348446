import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal as StrapModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import './modal.scss';

const Modal = ({
  title,
  bodyText,
  isOpen = false,
  className = 'save-modal',
  disableToggling = false,
  resetState,
  onClose,
}) => {
  const [modal, toggleModal] = useState(isOpen);

  useEffect(() => {
    toggleModal(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const cleanup = () => {
      if (window.history.state?.modal) {
        const state = { ...window.history.state };
        delete state.modal;
        window.history.replaceState(state, '');
      }
    };
    return cleanup;
  });

  useEffect(() => {
    if (!modal && isOpen && resetState) resetState();
  }, [modal]);

  const handleClose = () => {
    console.log('handleClose');
    toggleModal(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className="failureModal-modal">
      <StrapModal
        isOpen={modal}
        toggle={!disableToggling ? handleClose : null}
        backdrop={'static'}
        centered={true}
        className={className}
      >
        <div className="modal-content">
          <ModalHeader toggle={!disableToggling ? handleClose : null} />
          <ModalBody>
            <div>
              <h3 className="modal-title">{title}</h3>
            </div>
            <div className="modal-content">
              <div dangerouslySetInnerHTML={{ __html: bodyText }} />
            </div>
          </ModalBody>
          <ModalFooter className="modal-footer"></ModalFooter>
        </div>
      </StrapModal>
    </div>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  bodyText: PropTypes.string,
  isOpen: PropTypes.bool,
};

export default Modal;
